import { createApp } from 'vue';
import { VueMasonryPlugin } from 'vue-masonry';
import App from './App.vue';
import AppLoading from './components/common/app-loading.vue';
import { setupDirectives } from './directives';
import { setupRouter } from './router';
import { setupAssets } from './plugins';
import { setupStore } from './store';
import { setupI18n } from './locales';
import { setupFastCrud } from './plugins/fast-crud';
import { setupSentry } from './plugins/sentry';
import dayjs from 'dayjs'
import "dayjs/locale/zh-cn"
dayjs.locale('zh-cn')

async function setupApp() {
  // import assets: js、css
  setupAssets();

  // app loading
  const appLoading = createApp(AppLoading);

  appLoading.mount('#appLoading');

  const app = createApp(App);
  app.use(VueMasonryPlugin);

  // store plugin: pinia
  setupStore(app);

  // vue custom directives
  setupDirectives(app);

  // vue router
  const router = await setupRouter(app);
	setupSentry(app, router)

  setupI18n(app);
	setupFastCrud(app);


  appLoading.unmount();

  // mount app
  app.mount('#app');

}

setupApp();
